import React, { createContext, ReactNode, useContext, useState } from "react";

interface ContactContextType {
  isContactOpen: boolean;
  toggleContact: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialContactContextState: ContactContextType = {
  isContactOpen: false,
  toggleContact: () => {
    throw new Error(
      "You forgot to wrap this in a ContactContextProvider object"
    );
  },
};

const ContactContext = createContext<ContactContextType>(
  initialContactContextState
);

export function useContactContext(): ContactContextType {
  return useContext(ContactContext);
}

type ContactContextProviderProps = {
  children: ReactNode;
};

export const ContactContextProvider = (props: ContactContextProviderProps) => {
  const [isContactOpen, toggleContact] = useState(false);

  return (
    <ContactContext.Provider
      value={{
        isContactOpen,
        toggleContact,
      }}
    >
      {props.children}
    </ContactContext.Provider>
  );
};

export default ContactContext;
