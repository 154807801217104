import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import MorphSVGPlugin from "gsap/dist/MorphSVGPlugin";
import { useDeviceStateTracker } from "../../../hooks/useDeviceStateTracker";

gsap.registerPlugin(MorphSVGPlugin);

const pathHamburger = "M3.5 7.5h17M3.5 15.5h17";
const pathCross = "M18 6 6 18M6 6l12 12";

type HamburgerButtonProps = {
  onClick: ((
    e?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void) &
    React.MouseEventHandler<unknown>;
  menuOpened: boolean;
};

const HamburgerButton = ({ onClick, menuOpened }: HamburgerButtonProps) => {
  const pathRef = useRef<SVGPathElement>(null);
  const { deviceState } = useDeviceStateTracker(true);

  useEffect(() => {
    gsap.to(pathRef.current, {
      morphSVG: menuOpened ? pathCross : pathHamburger,
    });
  }, [menuOpened]);

  return (
    <button
      onClick={onClick}
      style={{
        height: deviceState > 2 ? 32 : 24,
        width: deviceState > 2 ? 32 : 24,
      }}
      aria-label="menu"
    >
      <svg
        width={deviceState > 2 ? 32 : 24}
        height={deviceState > 2 ? 32 : 24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={pathRef}
          stroke="#fff"
          strokeLinecap="square"
          d={pathHamburger}
        />
      </svg>
    </button>
  );
};

export default HamburgerButton;
