import React, { MouseEvent } from "react";
import styles from "./ContactForm.module.scss";
import ArrowRightSvg from "../../../assets/icons/arrow-right.svg";
import classNames from "classnames";
import CrossSvg from "../../../assets/icons/cross.svg";
import { useContactContext } from "../../../context/contactContext";
import useAnalytics from "../../../hooks/useAnalytics";

const TOTAL_SLIDES = 2;

interface ContactFormHeaderProps {
  currentSlide: number;
}

const ContactFormHeader = ({ currentSlide }: ContactFormHeaderProps) => {
  const { toggleContact } = useContactContext();
  const { trackButtonClick } = useAnalytics();

  function handleCloseContactForm(e: MouseEvent<HTMLButtonElement>) {
    toggleContact(false);
    trackButtonClick("ui interaction", "", e.currentTarget.innerText);
  }

  return (
    <div className={styles.modelHeaderHolder}>
      <div className={styles.modelHeaderControlsHolder}>
        {/* && !completedForm */}
        {currentSlide !== 1 && (
          <div
            className={styles.modelHeaderControlsBack}
            //@click.prevent="handlePreviousTab"
          >
            <ArrowRightSvg
              className={styles.blackIcon}
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        )}

        <div className={styles.modelHeaderControlsTextHolder}>
          <div
            className={classNames(
              styles.subheading5,
              styles.modelHeaderControlsText
            )}
          >
            Contactformulier
          </div>

          {currentSlide !== 0 && (
            <div
              className={classNames(
                styles.ml8,
                styles.modelHeaderControlsPageIndicator
              )}
            >
              <span className={styles.modelHeaderControlsPageIndicatorCurrent}>
                {currentSlide}
              </span>
              <span
                className={styles.modelHeaderControlsPageIndicatorSeparator}
              >
                /
              </span>
              <span className={styles.modelHeaderControlsPageIndicatorMax}>
                {TOTAL_SLIDES}
              </span>
            </div>
          )}
        </div>

        <button
          className={styles.modelHeaderCloseHolder}
          onClick={handleCloseContactForm}
          aria-label="Sluiten"
        >
          <CrossSvg
            className={classNames(
              styles.blackIcon,
              styles.icon32,
              styles.crossIcon
            )}
          />
        </button>
      </div>

      <div className={styles.modelHeaderIndicator}>
        <span
          className={styles.modelHeaderIndicatorCurrent}
          style={{
            transform: `translateX(${
              -100 + (100 / TOTAL_SLIDES) * currentSlide
            }%)`,
          }}
        />
      </div>
    </div>
  );
};

export default ContactFormHeader;
