import React, { FocusEvent, useEffect, useRef, useState } from "react";
import styles from "./ContactForm.module.scss";
import classNames from "classnames";
import ContactFormHeader from "./ContactForm.header";
import Slide1 from "./ContactForm.slide1";
import Slide2 from "./ContactForm.slide2";
import CompletedForm from "./ContactForm.completedForm";
import gsap from "gsap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FieldErrors,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useContactContext } from "../../../context/contactContext";
import useAnalytics from "../../../hooks/useAnalytics";
import camelize from "../../../utils/camelize";
import ClickableDiv from "../../atoms/clickable-div/ClickableDiv";

const schemaSlide1 = {
  "First name": Yup.string().required("Dit veld is verplicht."),
  "Last name": Yup.string().required("Dit veld is verplicht."),
  Email: Yup.string()
    .required("Dit veld is verplicht.")
    .email("Vul een geldig emailadres in."),
  Phone: Yup.string().required("Dit veld is verplicht."),
};

const schemaSlide2 = {
  subject: Yup.string().required("Dit veld is verplicht."),
  Message: Yup.string().required("Dit veld is verplicht."),
  terms: Yup.bool().oneOf([true], "Accepteer de voorwaarden om verder te gaan"),
};

function camelCaseFormDataKeys(data: FieldValues) {
  Object.keys(data).forEach(function (key) {
    const value = data[key];
    delete data[key];
    data[camelize(key)] = value;
  });
}

const ContactForm = () => {
  const { toggleContact, isContactOpen } = useContactContext();
  const {
    trackFormSubmit,
    trackFormFocus,
    trackFormError,
    trackFormStart,
    trackFormSuccess,
  } = useAnalytics();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [isSuccess, setSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [loading, setLoading] = useState(false);
  const formStarted = useRef(false);

  const backdropRef = useRef<HTMLDivElement>(null);

  let validationSchema = Yup.object().shape(schemaSlide1);

  if (currentSlide === 2) {
    validationSchema = Yup.object().shape({
      ...schemaSlide1,
      ...schemaSlide2,
    });
  }

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit: hookFormHandleSubmit,
    formState,
    getValues,
    setValue,
  } = useForm(formOptions);
  const { errors } = formState;

  // const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
  const handleSubmit: SubmitHandler<FieldValues> = async (data, event) => {
    event?.preventDefault();
    event?.stopPropagation();

    // camelcase data keys
    camelCaseFormDataKeys(data);
    trackFormSubmit(
      window.location.href,
      "Contact",
      "Garage.Ai contact form",
      data
    );

    setLoading(true);
    setSubmissionError("");

    const formData = new FormData(event?.target);
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    setLoading(false);
    if (response.success) {
      setSuccess(true);
      trackFormSuccess(
        window.location.href,
        "Contact",
        "Garage.Ai contact form"
      );
    } else {
      setSuccess(false);
      setSubmissionError(response.message);
    }
  };

  function handleFocusChange(
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (!formStarted.current) {
      trackFormStart(window.location.href, "Contact", "Garage.Ai contact form");
      formStarted.current = true;
    }
    const data = getValues();
    camelCaseFormDataKeys(data);
    trackFormFocus(
      window.location.href,
      "Contact",
      "Garage.Ai contact form",
      data
    );
  }

  // The errors are coming from slide2, so if everything is right on slide1 with can proceed
  const goToSlide2 = (errors: FieldErrors<FieldValues>) => {
    if (
      !errors["First name"] &&
      !errors["Last name"] &&
      !errors.Email &&
      !errors.Phone
    ) {
      setCurrentSlide(2);
    }
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (errors.hasOwnProperty(key) && errors[key]?.message) {
          trackFormError(
            window.location.href,
            "Contact",
            "Garage.Ai contact form",
            camelize(key),
            errors[key]?.message as string
          );
        }
      });
    }
  }, [errors, trackFormError]);

  useEffect(() => {
    if (isSuccess) {
      setValue("subject", "");
      setValue("Message", "");
      setCurrentSlide(0);
    }
  }, [isSuccess, setValue]);

  useEffect(() => {
    gsap.to(backdropRef.current, {
      opacity: isContactOpen ? 1 : 0,
      pointerEvents: isContactOpen ? "all" : "none",
    });

    if (!isContactOpen) {
      setCurrentSlide(1);
    }
  }, [isContactOpen]);

  useEffect(() => {
    if (!isContactOpen && currentSlide === 0) {
      setCurrentSlide(1);
      setSuccess(false);
      setSubmissionError("");
      setLoading(false);
      formStarted.current = false;
    }
  }, [isContactOpen, currentSlide]);

  return (
    <>
      <ClickableDiv
        className={styles.modelBackdrop}
        ref={backdropRef}
        onClick={() => toggleContact(false)}
      />
      <div className={styles.modelHolder}>
        <ContactFormHeader currentSlide={currentSlide} />

        <div className={styles.container}>
          <div className={classNames(styles["model__content-holder"])}>
            <form onSubmit={hookFormHandleSubmit(handleSubmit)}>
              <input
                type="hidden"
                name="access_key"
                value={process.env.NEXT_PUBLIC_WEB3FORM_KEY}
              />
              <input
                type="hidden"
                name="from_name"
                value="Garage.Ai contact form"
              />
              <input
                type="checkbox"
                name="botcheck"
                id=""
                style={{ display: "none" }}
              />

              {/* Need to keep them in the dom because the form is split between slide1 and slide2 */}
              <div
                style={{
                  visibility: currentSlide === 1 ? "visible" : "hidden",
                  height: currentSlide === 1 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                <Slide1
                  errors={errors}
                  register={register}
                  handleNext={hookFormHandleSubmit(
                    () => setCurrentSlide(2),
                    goToSlide2
                  )}
                  onFocusChange={handleFocusChange}
                  active={currentSlide === 1}
                />
              </div>

              {/* Need to keep them in the dom because the form is split between slide1 and slide2 */}
              <div
                style={{
                  visibility: currentSlide === 2 ? "visible" : "hidden",
                  height: currentSlide === 2 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                <Slide2
                  register={register}
                  loading={loading}
                  submissionError={submissionError}
                  errors={errors}
                  onFocusChange={handleFocusChange}
                  active={currentSlide === 2}
                />
              </div>
            </form>

            {currentSlide === 0 && <CompletedForm />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
