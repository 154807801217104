import React, { MouseEvent } from "react";
import classNames from "classnames";
import styles from "./ContactForm.module.scss";
import Image from "next/image";
import ArrowRightSvg from "../../../assets/icons/arrow-right.svg";
import { useContactContext } from "../../../context/contactContext";
import useAnalytics from "../../../hooks/useAnalytics";

const CompletedForm = () => {
  const { toggleContact } = useContactContext();
  const { trackButtonClick } = useAnalytics();

  function handleCloseContactForm(e: MouseEvent<HTMLButtonElement>) {
    toggleContact(false);
    trackButtonClick("ui interaction", "", e.currentTarget.innerText);
  }

  return (
    <>
      <div className={classNames(styles["mb-48"], styles["image-holder-16-9"])}>
        <Image
          src={"/porsche-taycan-light-blue.jpg"}
          alt="taycan-light-blue"
          fill
        />
      </div>

      <div className={classNames(styles["mb-8"], styles["model-title-holder"])}>
        <h2 className={styles.subheading2}>Contactformulier verstuurd</h2>
      </div>

      <div
        className={classNames(styles["body-standard"], styles.modelTextHolder)}
      >
        <p>
          Bedankt voor jouw contactverzoek. Porsche reageert zo snel mogelijk op
          jouw vraag.
        </p>
      </div>

      <div className={classNames(styles["model__cta-holder"])}>
        <button
          data-color-theme="dark"
          className={classNames(styles.button, styles["button--icon-right"])}
          onClick={handleCloseContactForm}
        >
          Sluiten
          <ArrowRightSvg className={styles.buttonIcon} />
        </button>
      </div>
    </>
  );
};

export default CompletedForm;
