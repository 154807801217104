import type {
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefObject,
} from "react";
import styles from "./ClickableDiv.module.scss";
import classNames from "classnames";
import { forwardRef, useRef } from "react";

export type ClickableDivRef = HTMLDivElement;
type ClickableDivProps = {
  children?: ReactNode;
  className?: string;
  onClick: (e: MouseEvent | KeyboardEvent) => void;
  onHoverChange?: (isHovering: boolean) => void;
} & HTMLAttributes<unknown>;

const ClickableDiv = forwardRef<ClickableDivRef, ClickableDivProps>(
  (
    { children, onClick, className, onHoverChange, ...rest },
    ref
  ): ReactElement => {
    const localRef = useRef(null);
    const elementRef = (ref || localRef) as RefObject<ClickableDivRef>;

    function handleKeyDown(e: KeyboardEvent) {
      if (e.code === "Enter" || e.code === "Space") {
        e.preventDefault();
        onClick(e);
      }
    }

    function handleMouseEnter() {
      if (onHoverChange) onHoverChange(true);
    }
    function handleMouseLeave() {
      if (onHoverChange) onHoverChange(false);
    }

    return (
      <div
        onKeyDown={handleKeyDown}
        role="button"
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        tabIndex={0}
        className={classNames(styles.clickableDiv, className, "cursor-link")}
        ref={elementRef}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

ClickableDiv.displayName = "Clickable Div";

export default ClickableDiv;
