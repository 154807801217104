import styles from "./Button.module.scss";
import ArrowRightSvg from "../../../assets/icons/arrow-right.svg";
import React, { forwardRef, MouseEventHandler } from "react";
import classNames from "classnames";
import type { LegacyRef } from "react";

type ButtonIconType = "arrow-right";

type ButtonProps = {
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  href?: string;
  label: string;
  className?: string;
  buttonIcon?: ButtonIconType;
  target?: string;
  rel?: string;
};

function ButtonIcon({ icon }: { icon: ButtonIconType }) {
  switch (icon) {
    case "arrow-right":
      return <ArrowRightSvg className={styles.buttonIcon} />;
    default:
      return null;
  }
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  ({ onClick, href, label, buttonIcon, className, ...props }, ref) => {
    if (href) {
      return (
        <a
          ref={ref as unknown as LegacyRef<HTMLAnchorElement>}
          href={href}
          onClick={onClick}
          className={classNames(styles.button, className)}
          {...props}
        >
          {label}
          {buttonIcon && <ButtonIcon icon={buttonIcon} />}
        </a>
      );
    }

    return (
      <button
        ref={ref as unknown as LegacyRef<HTMLButtonElement>}
        onClick={onClick}
        className={classNames(styles.button, className)}
        {...props}
      >
        {label}
        {buttonIcon && <ButtonIcon icon={buttonIcon} />}
      </button>
    );
  }
);
Button.displayName = "Button";

export default Button;
