import Head from "next/head";

const title = "Porsche Garage Mentality";
const metaDescription =
  "Een droom realiseren vraagt om veerkracht. Voor het ontstaan van de eerste Porsche was een ondernemende mentaliteit nodig. Bij Porsche noemen we dit de Garage Mentality.";
const author = "Porsche";

function Seo() {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="/assets/images/socialimage1.jpg" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        property="twitter:image"
        content="/assets/images/socialimage_twitter1.jpg"
      />
      <meta
        name="ahrefs-site-verification"
        content="12aa8e0fd60bb6f590ba854af292e4cb75a614b45a6c65536f9cc3317d43f9c0"
      />
      <meta
        name="google-site-verification"
        content="sw_ujVbxQZC8wgnxwpyYmr4k47HfhELGQTBveu0zHgk"
      />
    </Head>
  );
}

export default Seo;
