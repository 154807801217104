import classNames from "classnames";
import type { ReactElement, ReactNode } from "react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import styles from "./Accordion.module.scss";
import Image from "next/image";
import gsap from "gsap";
import useAnalytics from "../../../hooks/useAnalytics";

type AccordionRef = HTMLElement;
type AccordionProps = {
  title: string;
  children: ReactNode;
  iconUrl: string;
  secondaryIconUrl?: string;
  iconAlt?: string;
  className?: string;
  initialIsOpen?: boolean;
  interactive?: boolean;
};

const animationValues = {
  duration: 0.5,
  ease: "power1.inOut",
};

// https://greensock.com/forums/topic/28831-nested-accordion/

const Accordion = forwardRef<AccordionRef, AccordionProps>(
  (props, ref): ReactElement => {
    const { trackButtonClick } = useAnalytics();
    const localRef = useRef(null) || ref;
    const contentRef = useRef(null);
    const iconRef = useRef(null);
    const animationRef = useRef<(() => gsap.core.Timeline) | null>(null);

    useEffect(() => {
      if (!iconRef.current) return;

      gsap.set(contentRef.current, { height: "auto" });
      gsap.set(iconRef.current, { rotate: "-180deg" });

      const tl = gsap.timeline();
      const animation = tl
        .from(contentRef.current, {
          height: 0,
          reversed: true,
          ...animationValues,
        })
        .from(
          iconRef.current,
          {
            rotate: 0,
            reversed: true,
            ...animationValues,
          },
          "<"
        );

      if (props.initialIsOpen) {
        animation.reversed(true);
        animation.seek(animation.endTime());
        animation.seek(0);
      } else {
        animation.progress(animation.endTime() - 1);
        animation.progress(animation.endTime());
      }

      animationRef.current = () => animation.reversed(!animation.reversed());

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialIsOpen]);

    const [isOpen, setIsOpen] = useState(false);

    const onIconClick = useCallback(() => {
      trackButtonClick("ui interaction", "", props.title);
      setIsOpen(!isOpen);
      animationRef.current?.();
    }, [isOpen, props.title, trackButtonClick]);

    const iconUrl: string =
      isOpen && props.secondaryIconUrl ? props.secondaryIconUrl : props.iconUrl;

    return (
      <div
        className={classNames(styles.accordion, props.className)}
        ref={localRef}
      >
        <div className={styles.heading}>
          {props.interactive ? (
            <>
              <button onClick={onIconClick}>
                <h5 className="heading-05">{props.title}</h5>
              </button>
              <button onClick={onIconClick}>
                <Image
                  ref={iconRef}
                  src={iconUrl}
                  alt={props.iconAlt || "icon"}
                  height={24}
                  width={24}
                />
              </button>
            </>
          ) : (
            <h5 className="heading-05">{props.title}</h5>
          )}
        </div>

        <div ref={contentRef} className={styles.content}>
          {props.children}
        </div>
      </div>
    );
  }
);

Accordion.displayName = "Accordion";
export default Accordion;
