import React, { useEffect, useRef } from "react";
import ContactForm from "../../organisms/contact-form/ContactForm";
import styles from "./ContactOverlay.module.scss";
import { useContactContext } from "../../../context/contactContext";
import gsap from "gsap";

const ContactOverlay = () => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const { isContactOpen } = useContactContext();

  useEffect(() => {
    gsap.set(overlayRef.current, {
      xPercent: 100,
      opacity: 1,
    });
  }, []);

  useEffect(() => {
    gsap.to(overlayRef.current, {
      xPercent: isContactOpen ? 0 : 100,
    });
  }, [isContactOpen]);

  return (
    <div className={styles.overlay} ref={overlayRef}>
      <ContactForm />
    </div>
  );
};

export default ContactOverlay;
