import React, {
  useState,
  FocusEvent,
  useEffect,
  useRef,
  MouseEvent,
} from "react";
import classNames from "classnames";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import ErrorIcon from "../../../assets/icons/error.svg";
import ArrowRightSvg from "../../../assets/icons/arrow-right.svg";
import styles from "./ContactForm.module.scss";
import PhoneNumberInput from "../../molecules/phone-number-input/PhoneNumberInput";
import useAnalytics from "../../../hooks/useAnalytics";

interface Slide1Props {
  register: UseFormRegister<FieldValues>;
  handleNext: () => void;
  errors: FieldErrors<FieldValues>;
  onFocusChange: (e: FocusEvent<HTMLInputElement>) => void;
  active?: boolean;
}

const Slide1 = ({
  handleNext,
  register,
  errors,
  onFocusChange,
  active,
}: Slide1Props) => {
  const { trackFormStep, trackButtonClick } = useAnalytics();
  const isInitialised = useRef(false);
  const [phone, setPhone] = useState("+31");

  useEffect(() => {
    if (!isInitialised.current && active) {
      trackFormStep(
        window.location.href,
        "Contact",
        "Garage.Ai contact form",
        1,
        "Persoonlijke gegevens"
      );
      isInitialised.current = true;
    }
  }, [trackFormStep, active]);

  function handleNextButtonClick(e: MouseEvent<HTMLButtonElement>) {
    handleNext();
    trackButtonClick("navigation", "", e.currentTarget.innerText);
  }

  return (
    <>
      <div
        className={classNames(styles["mb-8"], styles["model__title-holder"])}
      >
        <h2 className={styles.subheading2}>Persoonlijke gegevens</h2>
      </div>
      <div
        className={classNames(styles["body-standard"], styles.modelTextHolder)}
      >
        <p>
          Je gegevens worden vertrouwelijk behandeld en we vragen alleen
          noodzakelijke informatie.
        </p>
      </div>

      <div className={classNames(styles["form-holder"])}>
        <div className={classNames(styles["grid"])}>
          <div className={classNames(styles["gridCol12"])}>
            <div className={classNames(styles.flexRow)}>
              <div
                className={classNames(styles["radio-button"], styles["mr-16"])}
                data-theme="darktheme"
              >
                <input
                  id="ms"
                  {...register("Gender")}
                  className={classNames(styles["radio-button__input"])}
                  type="radio"
                  value="female"
                  name="Gender"
                  onFocus={onFocusChange}
                />
                <label
                  className={classNames(styles["radio-button__label"])}
                  htmlFor="ms"
                >
                  Mevr.
                </label>
              </div>
              <div
                className={classNames(styles["mr-16"], styles["radio-button"])}
                data-theme="darktheme"
              >
                <input
                  id="mr"
                  {...register("Gender")}
                  className={classNames(styles["radio-button__input"])}
                  type="radio"
                  value="male"
                  name="Gender"
                  onFocus={onFocusChange}
                />
                <label
                  className={classNames(styles["radio-button__label"])}
                  htmlFor="mr"
                >
                  Dhr.
                </label>
              </div>
              <div
                className={classNames(styles["radio-button"])}
                data-theme="darktheme"
              >
                <input
                  id="other"
                  {...register("Gender")}
                  className={classNames(styles["radio-button__input"])}
                  type="radio"
                  value="other"
                  name="Gender"
                  onFocus={onFocusChange}
                />
                <label
                  className={classNames(styles["radio-button__label"])}
                  htmlFor="other"
                >
                  Neutraal
                </label>
              </div>
            </div>
          </div>

          <div className={classNames(styles["gridCol12"])}>
            <div className={classNames(styles.grid)}>
              <div
                className={classNames(
                  styles["gridCol6"],
                  styles["gridColMb12"],
                  styles["gridColTp6"]
                )}
              >
                <div className={classNames(styles["form__item-holder"])}>
                  <input
                    id="first_name"
                    type="text"
                    {...register("First name")}
                    className={classNames(styles["inputfield__input"], {
                      [styles["inputfield__error"]]: errors["First name"],
                    })}
                    onFocus={onFocusChange}
                  />
                  <label
                    className={classNames(
                      styles["mb-8"],
                      styles["inputfield__label"]
                    )}
                    htmlFor="first_name"
                  >
                    Voornaam
                  </label>
                  {errors["First name"]?.message && (
                    <span className={classNames(styles["form__error"])}>
                      <>
                        <ErrorIcon />
                        {errors["First name"].message}
                      </>
                    </span>
                  )}
                </div>
              </div>

              <div
                className={classNames(
                  styles["gridCol6"],
                  styles["gridColMb12"],
                  styles["gridColTp6"]
                )}
              >
                <div className={classNames(styles["form__item-holder"])}>
                  <input
                    id="last_name"
                    type="text"
                    {...register("Last name")}
                    className={classNames(styles["inputfield__input"], {
                      [styles["inputfield__error"]]: errors["Last name"],
                    })}
                    onFocus={onFocusChange}
                  />
                  <label
                    className={classNames(
                      styles["mb-8"],
                      styles["inputfield__label"]
                    )}
                    htmlFor="last_name"
                  >
                    Achternaam
                  </label>
                  {errors["Last name"]?.message && (
                    <span className={classNames(styles["form__error"])}>
                      <>
                        <ErrorIcon icon-id="error circle-1" />
                        {errors["Last name"].message}
                      </>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={classNames(styles["gridCol12"])}>
            <div className={classNames(styles["form__item-holder"])}>
              <input
                id="email"
                {...register("Email")}
                type="text"
                className={classNames(styles["inputfield__input"], {
                  [styles["inputfield__error"]]: errors.Email,
                })}
                onFocus={onFocusChange}
              />
              <label
                className={classNames(
                  styles["mb-8"],
                  styles["inputfield__label"]
                )}
                htmlFor="email"
              >
                E-mailadres
              </label>
              {errors.Email?.message && (
                <span className={classNames(styles["form__error"])}>
                  <>
                    <ErrorIcon icon-id="error circle-1" />
                    {errors.Email.message}
                  </>
                </span>
              )}
            </div>
          </div>

          <div className={classNames(styles["gridCol12"])}>
            <div
              className={classNames(
                styles["form__item-holder"],
                styles["formItemHolderColumn"]
              )}
            >
              <PhoneNumberInput
                value={phone}
                register={register}
                setValue={(phone: string) =>
                  setPhone("+" + phone.replace(/\D/g, ""))
                }
                onFocusChange={onFocusChange}
              />
              {errors.Phone?.message && (
                <span className={classNames(styles["form__error"])}>
                  <>
                    <ErrorIcon />
                    {errors.Phone.message}
                  </>
                </span>
              )}
            </div>
          </div>
        </div>

        <div className={classNames(styles["model__cta-holder"])}>
          <button
            data-color-theme="dark"
            className={classNames(styles.button, styles["button--icon-right"])}
            type="button"
            onClick={handleNextButtonClick}
            // @click.prevent="onPersonalInfoSumbit(); formStepTracking(1, 'Personal info');"
          >
            Verder
            <ArrowRightSvg className={styles.buttonIcon} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Slide1;
