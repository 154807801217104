import React, { FocusEvent, MouseEvent, useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./ContactForm.module.scss";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import ErrorIcon from "../../../assets/icons/error.svg";
import ArrowRightSvg from "../../../assets/icons/arrow-right.svg";
import DotsSpinner from "../../atoms/dots-spinner/DotsSpinner";
import useAnalytics from "../../../hooks/useAnalytics";

interface Slide2Props {
  loading: boolean;
  submissionError: string;
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  onFocusChange: (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  active?: boolean;
}

const Slide2 = ({
  loading,
  submissionError,
  errors,
  register,
  onFocusChange,
  active,
}: Slide2Props) => {
  const { trackFormStep, trackButtonClick } = useAnalytics();
  const isInitialised = useRef(false);

  useEffect(() => {
    if (!isInitialised.current && active) {
      trackFormStep(
        window.location.href,
        "Contact",
        "Garage.Ai contact form",
        2,
        "Stel jouw vraag"
      );
      isInitialised.current = true;
    }
  }, [active, trackFormStep]);

  function handleSubmitClick(e: MouseEvent<HTMLButtonElement>) {
    const target = e.currentTarget;
    trackButtonClick("submit", "", target.innerText);
  }

  return (
    <>
      <div className={styles["mb-8"]}>
        <h2 className={styles.subheading3}>Stel jouw vraag</h2>
      </div>

      <div className={styles.modelTextHolder}>
        {/*<p>Wij beschermen uw gegevens en vragen alleen noodzakelijke informatie.</p>*/}
      </div>

      <div>
        <div className={styles.grid}>
          <div className={styles["grid-col-12"]}>
            <div className={styles["form__item-holder"]}>
              <input
                id="subject"
                type="text"
                {...register("subject")}
                className={classNames(styles["inputfield__input"], {
                  [styles["inputfield__error"]]: errors.subject,
                })}
                onFocus={onFocusChange}
              />
              <label
                className={classNames(
                  styles["mb-8"],
                  styles["inputfield__label"]
                )}
                htmlFor="subject"
              >
                Onderwerp
              </label>
              {errors.subject?.message && (
                <span className={classNames(styles["form__error"])}>
                  <>
                    <ErrorIcon />
                    {errors.subject.message}
                  </>
                </span>
              )}
            </div>
          </div>

          <div className={styles["grid-col-12"]}>
            <div className={styles["form__item-holder"]}>
              <div className={styles["textarea-holder"]}>
                <textarea
                  id="textarea"
                  className={classNames(styles["textarea-field"], {
                    [styles["inputfield__error"]]: errors.Message,
                  })}
                  {...register("Message")}
                  name="Message"
                  onFocus={onFocusChange}
                />
                <label
                  className={classNames(
                    styles["mb-8"],
                    styles["textarea__label"]
                  )}
                  htmlFor="textarea"
                >
                  Jouw bericht
                </label>
              </div>
              {errors.Message?.message && (
                <span className={classNames(styles["form__error"])}>
                  <>
                    <ErrorIcon />
                    {errors.Message.message}
                  </>
                </span>
              )}
            </div>
          </div>

          <div
            className={classNames(
              styles["mt-40"],
              styles["mb-32"],
              styles["grid-col-12"]
            )}
          >
            <div className={styles.checkbox} data-theme="darktheme">
              <input
                id="terms"
                {...register("terms")}
                className={styles["checkbox__input"]}
                type="checkbox"
                onFocus={onFocusChange}
              />
              <label className={styles["checkbox__label"]} htmlFor="terms">
                Ik heb de{" "}
                <a
                  className="link"
                  href="https://www.porsche.com/netherlands/nl/legal-notice/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacyverklaring
                </a>{" "}
                gelezen en begrepen.
              </label>
              {errors.terms?.message && (
                <span className={classNames(styles["form__error-checkbox"])}>
                  <>
                    <ErrorIcon />
                    {errors.terms.message}
                  </>
                </span>
              )}
            </div>

            <div className={styles.checkbox} data-theme="darktheme">
              <input
                id="newsletter"
                {...register("newsletter")}
                name="Subscribed to Newsletter"
                className={styles["checkbox__input"]}
                type="checkbox"
                onFocus={onFocusChange}
              />
              <label className={styles["checkbox__label"]} htmlFor="newsletter">
                Ik ontvang graag Porsche nieuwsbrieven en updates, zoals
                promotiemateriaal en uitnodigingen voor evenementen.
              </label>
            </div>
          </div>
        </div>

        <div className={classNames(styles.modelCtaHolder)}>
          <button
            data-color-theme="dark"
            className={classNames(styles.button, styles["button--icon-right"])}
            type="submit"
            onClick={handleSubmitClick}
          >
            Verstuur
            {loading ? (
              <DotsSpinner />
            ) : (
              <ArrowRightSvg className={styles.buttonIcon} />
            )}
          </button>

          {submissionError && (
            <span className={classNames(styles["form__error"])}>
              <>
                <ErrorIcon />
                Oops, er ging iets fout : {submissionError}
              </>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Slide2;
