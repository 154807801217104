import React, { createContext, ReactNode, useContext, useState } from "react";

interface SkipContextType {
  isSkipping: boolean;
  toggleSkip: React.Dispatch<React.SetStateAction<boolean>>;
  inSkippingContext: boolean;
  toggleSkippingContext: React.Dispatch<React.SetStateAction<boolean>>;
  backToStart: boolean;
  toggleBackToStart: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialSkipContextState: SkipContextType = {
  isSkipping: false,
  toggleSkip: () => {
    throw new Error("You forgot to wrap this in a SkipContextProvider object");
  },
  inSkippingContext: false,
  toggleSkippingContext: () => {
    throw new Error("You forgot to wrap this in a SkipContextProvider object");
  },
  backToStart: false,
  toggleBackToStart: () => {
    throw new Error("You forgot to wrap this in a SkipContextProvider object");
  },
};

const SkipContext = createContext<SkipContextType>(initialSkipContextState);

export function useSkipContext(): SkipContextType {
  return useContext(SkipContext);
}

type SkipContextProviderProps = {
  children: ReactNode;
};

export const SkipContextProvider = (props: SkipContextProviderProps) => {
  const [isSkipping, toggleSkip] = useState(false);
  const [inSkippingContext, toggleSkippingContext] = useState(false);
  const [backToStart, toggleBackToStart] = useState(false);

  return (
    <SkipContext.Provider
      value={{
        isSkipping,
        toggleSkip,
        inSkippingContext,
        toggleSkippingContext,
        backToStart,
        toggleBackToStart,
      }}
    >
      {props.children}
    </SkipContext.Provider>
  );
};

export default SkipContext;
