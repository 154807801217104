import React from "react";
import styles from "./NewsletterCard.module.scss";
import RedHalfCircle from "../../../assets/red-half-circle.svg";
import Button from "../../atoms/button/Button";
import useAnalytics from "../../../hooks/useAnalytics";

const NewsletterCard = () => {
  const { trackButtonClick } = useAnalytics();

  function handleNewsLetterCardClick() {
    trackButtonClick(
      "navigation",
      "https://campaign.porsche.nl/subscribe?utm_source=garagementality_campagnewebsite&utm_campaign=garagementality&utm_content=menu",
      "Meld je nu aan"
    );
  }

  return (
    <a
      className={styles.container}
      href={
        "https://campaign.porsche.nl/subscribe?utm_source=garagementality_campagnewebsite&utm_campaign=garagementality&utm_content=menu"
      }
      onClick={handleNewsLetterCardClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.textContainer}>
        <h2 className={styles.title}>
          Discovered<span className={styles.red}>.</span>
        </h2>
        <h2 className={styles.title}>Ongewone verhalen</h2>
        <p className={styles.subtitle}>
          Meld je aan voor onze nieuwsbrief en laat je inspireren door andere
          ondernemers.
        </p>
        <Button
          label="Meld je nu aan"
          buttonIcon="arrow-right"
          data-color-theme="light"
          className={styles.button}
        />
      </div>
      <RedHalfCircle className={styles.rightVector} />
    </a>
  );
};

export default NewsletterCard;
