import React from "react";
import styles from "./SoundButton.module.scss";
import { useDeviceStateTracker } from "../../../hooks/useDeviceStateTracker";
import classNames from "classnames";

type SoundButtonProps = {
  onClick: ((
    e?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void) &
    React.MouseEventHandler<unknown>;
  muted: boolean;
};

const SoundButton = ({ onClick, muted }: SoundButtonProps) => {
  const { deviceState } = useDeviceStateTracker(true);

  return (
    <button
      onClick={onClick}
      className={classNames(styles.soundButton, { [styles.muted]: muted })}
      style={{
        height: deviceState > 2 ? 24 : 14,
        width: deviceState > 2 ? 24 : 14,
      }}
    >
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
    </button>
  );
};

export default SoundButton;
