import { Howl, Howler } from "howler";

export default class AudioListener {
  static instance: AudioListener;
  public isPlaying: boolean = false;
  public muted: boolean = false;
  public levels: Array<number> = [];
  public volume: number = 0;
  private readonly fftSize: number = 256;
  private audio?: Howl = undefined;
  private analyserNode?: AnalyserNode = undefined;
  private floatTimeDomainData = new Float32Array(this.fftSize);
  private byteFrequencyData = new Uint8Array(this.fftSize);

  constructor() {
    if (AudioListener.instance) {
      return AudioListener.instance;
    }
    AudioListener.instance = this;
  }

  fadeOutAndPlayNewAudio(
    src: string,
    callbacks?: {
      onPlay?: () => void;
      onEnd?: () => void;
    }
  ) {
    if (this.audio && this.isPlaying) {
      this.audio.once("fade", () => {
        this.isPlaying = false;
        this.loadAudio(src, callbacks);
        this.playAudio(callbacks);
      });
      this.audio.fade(1, 0, 0.75);
    } else {
      this.loadAudio(src, callbacks);
      this.playAudio(callbacks);
    }
  }

  fadeOut() {
    if (this.audio && this.isPlaying) {
      this.audio.fade(1, 0, 0.75);
    }
  }

  loadAudio(
    src: string,
    callbacks?: {
      onPlay?: () => void;
    }
  ) {
    this.audio?.stop();
    this.audio = new Howl({
      src,
      rate: 1,
      mute: this.muted,
      volume: 1,
      autoplay: false,
      onplay: () => {
        this.isPlaying = true;
      },
      onend: () => {
        this.isPlaying = false;
      },
    });
    this.analyserNode = Howler.ctx.createAnalyser();
    this.analyserNode.fftSize = this.fftSize;
    Howler.masterGain.connect(this.analyserNode);
    // this.analyserNode.connect(Howler.ctx.destination);
    if (callbacks?.onPlay) this.audio.once("play", callbacks.onPlay);
  }

  playAudio(callbacks?: { onEnd?: () => void }) {
    if (this.audio) {
      if (callbacks?.onEnd) this.audio.once("end", callbacks.onEnd);
      this.audio.play();
      this.isPlaying = true;
    }
  }

  getLevels() {
    if (this.analyserNode) {
      const bufferLength = this.analyserNode.fftSize;
      const levelCount = 8;
      const levelBins = Math.floor(bufferLength / levelCount);

      const levels = [];
      let max = 0;

      for (let i = 0; i < levelCount; i++) {
        let sum = 0;

        for (let j = 0; j < levelBins; j++) {
          sum += this.byteFrequencyData[i * levelBins + j];
        }

        const value = sum / levelBins / 256;
        levels[i] = value;

        if (value > max) max = value;
      }

      return levels;
    }
    return this.levels;
  }

  getVolume() {
    let sumSquares = 0.0;
    // @ts-ignore
    for (const amplitude of this.floatTimeDomainData) {
      sumSquares += amplitude * amplitude;
    }

    return Math.sqrt(sumSquares / this.floatTimeDomainData.length);
  }

  update() {
    if (!this.analyserNode) return;

    // Retrieve audio data
    this.analyserNode.getByteFrequencyData(this.byteFrequencyData);
    if (
      this.analyserNode.hasOwnProperty("getFloatTimeDomainData") &&
      typeof this.analyserNode.getFloatTimeDomainData !== "undefined"
    ) {
      this.analyserNode?.getFloatTimeDomainData(this.floatTimeDomainData);
    }

    this.volume = this.getVolume();
    this.levels = this.getLevels();
  }

  destroy() {
    this.audio?.unload();
    this.audio = undefined;
  }

  setMuted(muted: boolean) {
    this.muted = muted;
    if (this.audio) this.audio.mute(muted);
  }
}
