import React from "react";
import styles from "./DotsSpinner.module.scss";

const DotsSpinner = () => {
  return (
    <div className={styles["lds-ellipsis"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default DotsSpinner;
