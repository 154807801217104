import * as React from "react";
import styles from "./Footer.module.scss";
import classNames from "classnames";
import Accordion from "../../molecules/accordion/Accordion";
import { useDeviceStateTracker } from "../../../hooks/useDeviceStateTracker";
import Facebook from "../../../assets/icons/social-fb.svg";
import Instagram from "../../../assets/icons/social-ig.svg";
import Linkedin from "../../../assets/icons/social-li.svg";
import Youtube from "../../../assets/icons/social-yt.svg";
import { useContactContext } from "../../../context/contactContext";
import Link from "next/link";
import useAnalytics from "../../../hooks/useAnalytics";
import { MouseEvent } from "react";

const Footer = () => {
  const { deviceState } = useDeviceStateTracker(true);
  const { trackLinkClick, trackButtonClick } = useAnalytics();

  const { toggleContact } = useContactContext();

  function handleLinkClick(e: MouseEvent<HTMLAnchorElement>) {
    const target = e.currentTarget;
    trackLinkClick("external", target.href, target.innerText, "footer_menu");
  }

  function handleContactClick(e: MouseEvent<HTMLButtonElement>) {
    const target = e.currentTarget;
    toggleContact(true);
    trackButtonClick("ui interaction", "", target.innerText);
  }

  return (
    <footer className={styles.footer}>
      <ul className={styles.sectionsContainer}>
        <li className={styles.section}>
          <Accordion
            title="Ontdek meer"
            initialIsOpen={deviceState > 2}
            interactive={deviceState <= 2}
            // without the key below the component is receiving deviceState > 2 false even for desktop
            key={deviceState > 2 ? "desktop" : "mobile"}
            iconUrl="/chevron-down.svg"
            iconAlt="chevrond down"
          >
            <ul>
              <li>
                <a
                  href="https://www.porsche.com/netherlands/nl/models/taycan/taycan-models/taycan/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  Porsche Taycan
                </a>
              </li>
              <li>
                <a
                  href="https://www.porsche.com/netherlands/nl/modelstart/all/?modelrange=taycan"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  Porsche configurator
                </a>
              </li>
              <li>
                <a
                  href="https://discovered.porsche.nl/?utm_source=garagementality_campagnewebsite&utm_medium=homepage&utm_campaign=garagementality&utm_content=footer"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  Ontdek Discovered
                </a>
              </li>
            </ul>
          </Accordion>
          <hr />
        </li>

        <li className={styles.section}>
          <Accordion
            title="Porsche Nederland"
            initialIsOpen={deviceState > 2}
            interactive={deviceState <= 2}
            key={deviceState > 2 ? "desktop" : "mobile"}
            iconUrl="/chevron-down.svg"
            iconAlt="chevrond down"
          >
            <ul>
              <li>
                <a
                  href="https://www.porsche.com/netherlands/nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  Bezoek Porsche.nl
                </a>
              </li>
              <li>
                <a
                  href="https://nieuws.porsche.nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  Laatste nieuws
                </a>
              </li>
              <li>
                <a
                  href="https://formulieren.porsche.nl/#/abonnee/aanmelden"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  Aanmelden voor e-mailnieuwsbrief
                </a>
              </li>
            </ul>
          </Accordion>
          <hr />
        </li>

        <li className={styles.section}>
          <Accordion
            title="Contact"
            initialIsOpen={deviceState > 2}
            interactive={deviceState <= 2}
            key={deviceState > 2 ? "desktop" : "mobile"}
            iconUrl="/chevron-down.svg"
            iconAlt="chevrond down"
          >
            <ul>
              <li>
                <a
                  href="https://www.porsche.com/netherlands/nl/dealersearch/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  Porsche Centrum Finder
                </a>
              </li>
              <li>
                <Link href="/faq" target="_blank" rel="noopener noreferrer">
                  Veelgestelde vragen
                </Link>
              </li>
              <li>
                <button
                  onClick={handleContactClick}
                  className={styles.clickableDiv}
                >
                  Neem contact op
                </button>
              </li>
            </ul>
          </Accordion>
          <hr />
        </li>

        <li className={styles.social}>
          <p className="grey-40">Volg Porsche</p>
          <ul className={classNames(styles.flexLine, styles.gridGap24)}>
            <li>
              <a
                href="https://www.instagram.com/porsche_nederland/"
                className={styles.socialIcon}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <Instagram />
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/showcase/porsche-nederland/"
                className={styles.socialIcon}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <Linkedin />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@porschenl"
                className={styles.socialIcon}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Youtube />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/porsche/"
                className={styles.socialIcon}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <Facebook className={styles.facebook} />
              </a>
            </li>
          </ul>
        </li>

        <li className={styles.legal}>
          <p>© 2023 Porsche Nederland</p>
          <ul className={classNames(styles.legalLinksContainer)}>
            <li>
              <a
                className={styles.legalLink}
                href="https://www.porsche.com/netherlands/nl/legal-notice/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                Copyright & privacy.
              </a>
            </li>
            <li>
              <Link
                className={styles.legalLink}
                href="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Campagnevoorwaarden
              </Link>
            </li>
            <li>
              <a
                className={styles.legalLink}
                href="https://www.porsche.com/netherlands/nl/cookies/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                Cookies
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
