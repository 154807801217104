import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import AudioListener from "../lib/AudioListener/AudioListener";
import useIsomorphicLayoutEffect from "../hooks/useIsomorphicLayoutEffect";
import Bowser from "bowser";

interface GarageContextType {
  debug: boolean;
  smallScreen: boolean;
  screenSizeOffset: number;
  muted: boolean;
  toggleMuted: () => void;
  audioPlaying: boolean;
  setAudioPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isOldIosVersion: boolean;
}

const initialGarageContextState: GarageContextType = {
  debug: false,
  smallScreen: false,
  screenSizeOffset: 0,
  muted: false,
  toggleMuted: () => {
    throw new Error(
      "You forgot to wrap this in a GarageContextProvider object"
    );
  },
  audioPlaying: false,
  setAudioPlaying: () => {
    throw new Error(
      "You forgot to wrap this in a GarageContextProvider object"
    );
  },
  isOldIosVersion: false,
};

const GarageContext = createContext<GarageContextType>(
  initialGarageContextState
);

export function useGarageContext(): GarageContextType {
  return useContext(GarageContext);
}

type GarageContextProviderProps = {
  debug: boolean;
  smallScreen: boolean;
  children?: ReactNode;
};

export const GarageContextProvider = ({
  debug,
  smallScreen,
  children,
}: GarageContextProviderProps) => {
  const [muted, setMuted] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [isOldIosVersion, setIsOldIosVersion] = useState(false);
  const audioListenerRef = useRef(new AudioListener());

  const toggleMuted = useCallback(() => {
    setMuted(!muted);
    audioListenerRef.current.setMuted(!muted);
  }, [muted]);

  useIsomorphicLayoutEffect(() => {
    if (window) {
      const browser = Bowser.getParser(window.navigator.userAgent);
      if (
        browser.getBrowserName(true) === "safari" &&
        parseFloat(browser.getBrowserVersion()) <= 13
      ) {
        setIsOldIosVersion(true);
      }
    }
  }, []);

  return (
    <GarageContext.Provider
      value={{
        debug,
        smallScreen,
        screenSizeOffset: smallScreen ? 3 : 0,
        muted,
        toggleMuted,
        audioPlaying,
        setAudioPlaying,
        isOldIosVersion,
      }}
    >
      {children}
    </GarageContext.Provider>
  );
};

export function useIsDebug(): boolean {
  return useContext(GarageContext).debug;
}

export default GarageContext;
