import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useRef } from "react";
import { useGarageContext } from "../../../context/garageContext";
import { useMenuContext } from "../../../context/menuContext";
import { useSkipContext } from "../../../context/skipContext";
import useAnalytics from "../../../hooks/useAnalytics";
import { useDeviceStateTracker } from "../../../hooks/useDeviceStateTracker";
import useIsomorphicLayoutEffect from "../../../hooks/useIsomorphicLayoutEffect";
import AudioListener from "../../../lib/AudioListener/AudioListener";
import HamburgerButton from "../../atoms/hamburger-button/HamburgerButton";
import SoundButton from "../../atoms/sound-button/SoundButton";
import styles from "./Header.module.scss";

const Header = ({ hasSound }: { hasSound?: boolean }) => {
  const { toggleMenu, isMenuOpen } = useMenuContext();
  const { toggleMuted, muted } = useGarageContext();
  const { deviceState } = useDeviceStateTracker(true);
  const { trackButtonClick } = useAnalytics();
  const audioListenerRef = useRef(new AudioListener());
  const { toggleBackToStart } = useSkipContext();

  function handleMenuButtonClick() {
    toggleMenu((o) => !o);
    trackButtonClick("ui interaction", "", "Menu");
  }

  function handleMuteButtonClick() {
    toggleMuted();
    trackButtonClick("ui interaction", "", "Mute");
  }

  const { asPath } = useRouter();

  useIsomorphicLayoutEffect(() => {
    if (asPath.includes("#ai")) {
      toggleMenu(false);
    }
  }, [asPath, toggleMenu]);

  const getLogoHeight = () => {
    switch (deviceState) {
      case 0:
      case 1:
        return 40;
      case 2:
        return 50;
      case 3:
      default:
        return 50;
    }
  };

  const getLogoWidth = () => {
    switch (deviceState) {
      case 0:
      case 1:
        return 80;
      case 2:
        return 100;
      case 3:
      default:
        return 100;
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.leftPart}>
        {hasSound && (
          <SoundButton onClick={handleMuteButtonClick} muted={muted} />
        )}
      </div>

      <Link
        href={"/"}
        onClick={() => {
          audioListenerRef.current.fadeOut();
          toggleBackToStart(true);
          toggleMenu(false);
        }}
      >
        <Image
          src="/porsche_logo_white.svg"
          alt="Porsche Garage Mentality"
          height={getLogoHeight()}
          width={getLogoWidth()}
          quality={100}
          priority
        />
      </Link>

      <div className={styles.rightPart}>
        <HamburgerButton
          onClick={handleMenuButtonClick}
          menuOpened={isMenuOpen}
        />
      </div>
    </header>
  );
};

export default Header;
