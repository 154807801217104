import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import Header from "./layout/header/Header";
import Menu from "./molecules/menu/Menu";
import GridChecker from "./utils/grid-checker/GridChecker";
import { MenuContextProvider } from "../context/menuContext";
import { ContactContextProvider } from "../context/contactContext";
import Seo from "./seo";
import ContactOverlay from "./layout/contact-overlay/ContactOverlay";
import useWindowSize from "../hooks/useWindowSize";

interface LayoutProps {
  className?: string;
  children: ReactNode;
  hasSound?: boolean;
}

const Layout = ({ className, children, hasSound }: LayoutProps) => {
  const [windowHeight, setWindowHeight] = useState<number | undefined>(
    undefined
  );
  const windowSize = useWindowSize();

  useEffect(() => {
    setWindowHeight(windowSize.height);
  }, [windowSize.height]);

  return (
    <>
      <Seo />

      <ContactContextProvider>
        <MenuContextProvider>
          <Header hasSound={hasSound} />
          <Menu />
          <ContactOverlay />
        </MenuContextProvider>
      </ContactContextProvider>

      <main
        className={className}
        style={
          {
            // Need to do screen height calculations to take into account the iphone url bar
            "--screen-height": windowHeight ? `${windowHeight}px` : "100vh",
          } as React.CSSProperties
        }
      >
        {children}
      </main>

      {process.env.NODE_ENV === "development" && <GridChecker />}
    </>
  );
};

export default Layout;
