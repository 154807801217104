import React, { FocusEvent, useEffect, useState } from "react";
import styles from "./PhoneNumberInput.module.scss";
import classNames from "classnames";
import Image from "next/image";
import { COUNTRY_DATA } from "../../../data/shared-variable/countryData";
import { FieldValues, UseFormRegister } from "react-hook-form";

const hasError = false;

const baseSrc = "/assets/flags/";

interface PhoneNumberInputProps {
  value: string;
  setValue: (value: string) => void;
  register?: UseFormRegister<FieldValues>;
  onFocusChange: (e: FocusEvent<HTMLInputElement>) => void;
}

const PhoneNumberInput = ({
  value,
  setValue,
  register,
  onFocusChange,
}: PhoneNumberInputProps) => {
  const [countryCode, setCountryCode] = useState("nl");

  useEffect(() => {
    for (let i = 2; i <= 5; i++) {
      if (COUNTRY_DATA[value.slice(0, i)]) {
        setCountryCode(COUNTRY_DATA[value.slice(0, i)].code);
        return;
      }
    }
  }, [value]);

  return (
    <div className={styles["phone-input-container"]}>
      <label
        htmlFor="phone"
        className={classNames(styles["mb-8"], styles["phone-input__label"])}
      >
        Telefoonnummer
        <span className={styles["inputfield__label-optional"]}>
          (Optioneel)
        </span>
      </label>

      <div className={styles.inputContainer}>
        <input
          type="tel"
          id="phone"
          {...register?.("Phone")}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          className={classNames(
            styles["inputfield__input"],
            styles["phone-input__phone-holder"],
            {
              [styles["inputfield__error"]]: hasError,
            }
          )}
          onFocus={onFocusChange}
        />
        <span className={styles.flag}>
          <Image
            src={baseSrc + countryCode + ".svg"}
            alt={"flag of " + countryCode}
            fill
          />
        </span>
      </div>
    </div>
  );
};

export default PhoneNumberInput;
