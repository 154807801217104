import React, { MouseEvent, useEffect, useRef } from "react";
import Link from "next/link";
import gsap from "gsap";
import Footer from "../../layout/footer/Footer";
import NewsletterCard from "../newsletter-card/NewsletterCard";
import { useMenuContext } from "../../../context/menuContext";
import styles from "./Menu.module.scss";
import useAnalytics from "../../../hooks/useAnalytics";

const menuUp = "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)";
const menuDown = "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)";

const Menu = () => {
  const menuRef = useRef(null);
  const tlRef = useRef<gsap.core.Timeline | null>(null);
  const { trackLinkClick } = useAnalytics();

  const { isMenuOpen } = useMenuContext();

  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { duration: 0.5, ease: "power1.inOut" },
    });

    tlRef.current = tl.fromTo(
      menuRef.current,
      { clipPath: menuUp, opacity: 1, visibility: "hidden" },
      { clipPath: menuDown, opacity: 1, visibility: "visible" }
    );
  }, []);

  useEffect(() => {
    if (tlRef.current?.reversed()) {
      tlRef.current?.play();
    } else {
      tlRef.current?.reverse();
    }
  }, [isMenuOpen]);

  function handleLinkClick(e: MouseEvent<HTMLAnchorElement>) {
    const target = e.currentTarget;
    trackLinkClick("internal", target.href, target.innerText, "main_menu");
  }

  return (
    <div ref={menuRef} className={styles.menu}>
      <div className={styles.flexContainer}>
        <ul className={styles.menuItemsContainer}>
          <li className={styles.menuItem}>
            <Link
              href="garage-mentality"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLinkClick}
            >
              Over Garage Mentality
            </Link>
          </li>
          <li className={styles.menuItem}>
            <Link href={"/#ai"} onClick={handleLinkClick}>
              Porsche AI-tool
            </Link>
          </li>
        </ul>
        <div className={styles.newsletterContainer}>
          <NewsletterCard />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Menu;
