import React, { createContext, ReactNode, useContext, useState } from "react";

interface MenuContextType {
  isMenuOpen: boolean;
  toggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialMenuContextState: MenuContextType = {
  isMenuOpen: false,
  toggleMenu: () => {
    throw new Error("You forgot to wrap this in a MenuContextProvider object");
  },
};

const MenuContext = createContext<MenuContextType>(initialMenuContextState);

export function useMenuContext(): MenuContextType {
  return useContext(MenuContext);
}

type MenuContextProviderProps = {
  children: ReactNode;
};

export const MenuContextProvider = (props: MenuContextProviderProps) => {
  const [isMenuOpen, toggleMenu] = useState(false);

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        toggleMenu,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};

export default MenuContext;
